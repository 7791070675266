/*
    ----------
*/
$Font : (
    "Default" : "GT Walsheim"
);
/* 
    ----------
*/
$Size : (
    "10" : 10px,
    "11" : 11px,
    "12" : 12px,
    "13" : 13px,
    "14" : 14px,
    "15" : 15px,
    "16" : 16px,
    "17" : 17px,
    "18" : 18px,
    "19" : 19px,
    "20" : 20px
);
/* 
    ---------- 
*/
$Color : (
    "Default" : #2AB939,
    "White"   : #FFFFFF,
    "Black"   : #192919,
    "Grey"    : #6D756D,
    "Light"   : #E9EFE5
);
/* 
    ----------
*/
.dE5574{ font-family: map-get( $Font, "Default" ) }
/* 
    ----------
*/
.S6pt64{ float: left  }
.yJ452A{ float: right }
.eTt257{ float: none  }
/* 
    ----------
*/
.q7h2M9{ width: 25%  }
.S09r6B{ width: 50%  }
.b7P443{ width: 75%  }
.C96Q4r{ width: 100% }
/* 
    ----------
*/
.eU7H63{ font-size: map-get( $Size, "10" ) }
.S7gw68{ font-size: map-get( $Size, "11" ) }
.iI0402{ font-size: map-get( $Size, "12" ) }
.xG0j09{ font-size: map-get( $Size, "13" ) }
.PlO170{ font-size: map-get( $Size, "14" ) }
.wI860z{ font-size: map-get( $Size, "15" ) }
.Wm8G06{ font-size: map-get( $Size, "16" ) }
.bS8S40{ font-size: map-get( $Size, "17" ) }
.s4U1j1{ font-size: map-get( $Size, "18" ) }
.J589Ta{ font-size: map-get( $Size, "19" ) }
.i260Up{ font-size: map-get( $Size, "20" ) }
/* 
    ----------
*/
.C2y88Q{ font-weight: lighter }
.u37E41{ font-weight: normal  }
.Y4674t{ font-weight: bold    }
.zK3920{ font-weight: bolder  }
/* 
    ----------
*/
.F2n16X{ font-weight: 100 }
.Y8k86e{ font-weight: 200 }
.y3Or88{ font-weight: 300 }
.nS533w{ font-weight: 400 }
.K0cW96{ font-weight: 500 }
.D933nr{ font-weight: 600 }
.jS8601{ font-weight: 700 }
.Zv29i2{ font-weight: 800 }
.Is1259{ font-weight: 900 }
/* 
    ----------
*/
.J284Xb{ font-style: normal  }
.Ep1U39{ font-style: oblique }
.R6t03r{ font-style: italic  }
/* 
    ----------
*/
.v6EU83{ color: map-get( $Color, "Default" ) }
.M99v3q{ color: map-get( $Color, "White" )   }
.vOx602{ color: map-get( $Color, "Black" )   }
.Y0u334{ color: map-get( $Color, "Grey" )    }
.r4K8f4{ color: map-get( $Color, "Light" )   }
/* 
    ----------
*/
.cvC365{ fill: map-get( $Color, "Default" ) }
.f8T6I8{ fill: map-get( $Color, "White" )   }
.sRU313{ fill: map-get( $Color, "Black" )   }
.Vr4196{ fill: map-get( $Color, "Grey" )    }
.b29YY3{ fill: map-get( $Color, "Light" )   }
/* 
    ----------
*/
.u0Bc59{ background: map-get( $Color, "Default" ) }
.rP408N{ background: map-get( $Color, "White" )   }
.u3I753{ background: map-get( $Color, "Black" )   }
.i3eE58{ background: map-get( $Color, "Grey" )    }
.gZ959C{ background: map-get( $Color, "Light" )   }
/* 
    ----------
*/
.wDC543{ display: inline-block }
.eF429z{ display: inline-flex  }
.xA723K{ display: table-cell   }
.X35k4K{ display: table-row    }
.bN2p30{ display: inline       }
.S0u16d{ display: flex         }
.V81zg1{ display: block        }
.oi8E43{ display: grid         }
.zD661i{ display: table        }
.t3c0N7{ display: -webkit-box  }
.hPP928{ display: none         }
.s6D202{ display: list-item    }
/* 
    ----------
*/
.fE0d48{ flex-direction: column         }
.L640Ti{ flex-direction: column-reverse }
.Y065Fd{ flex-direction: row-reverse    }
.fr598R{ flex-direction: row            }
/* 
    ----------
*/
.ZH339f{ justify-content: flex-start    }
.U080Kf{ justify-content: flex-end      }
.vR05U6{ justify-content: center        }
.g1aS48{ justify-content: space-between }
.h18Ea4{ justify-content: space-around  }
.aR51Y0{ justify-content: space-evenly  }
/* 
    ----------
*/
.I4t79h{ align-items: normal     }
.nA9305{ align-items: stretch    }
.d721Vx{ align-items: center     }
.Q3n44O{ align-items: flex-start }
.J7l0G2{ align-items: flex-end   }
.W44s9p{ align-items: start      }
.C6u87n{ align-items: end        }
.Q7Ae62{ align-items: baseline   }
/* 
    ----------
*/
.xD280K{ text-align: start   }
.Q606yt{ text-align: end     }
.vC9r15{ text-align: center  }
.yyO233{ text-align: justify }
/* 
    ----------
*/
.uUG847{ text-transform: lowercase  }
.GzJ197{ text-transform: uppercase  }
.cMq044{ text-transform: capitalize }
.e2M03y{ text-transform: none       }
/*
    ----------
*/
.vF5k47{ text-decoration: none         }
.F83vw7{ text-decoration: dashed       }
.a20K95{ text-decoration: dotted       }
.C71Dm7{ text-decoration: double       }
.El6174{ text-decoration: solid        }
.r9zX81{ text-decoration: overline     }
.sB248t{ text-decoration: wavy         }
.Q3s2S1{ text-decoration: underline    }
.h6T64Y{ text-decoration: line-through }
/*
    ----------
*/
.cB3332{ text-underline-offset: 10px }
/*
   ----------
*/
.q8K60t{ text-overflow: ellipsis }
.faJ087{ text-overflow: clip     }
.m1BW70{ text-overflow: unset    }
/*
    ----------
*/
.U596Kr{ vertical-align: baseline    }
.t0G834{ vertical-align: sub         }
.ebZ371{ vertical-align: super       }
.I7e03O{ vertical-align: top         }
.j844Eb{ vertical-align: text-top    }
.eQ738Y{ vertical-align: middle      }
.E0vm38{ vertical-align: bottom      }
.z56Q50{ vertical-align: text-bottom }
/*
    ----------
*/
.gL0474{ white-space: normal }
.wqA166{ white-space: nowrap }
.Q7a1D3{ white-space: pre    }
/* 
    ----------
*/
.cH8711{ position: relative }
.gBt584{ position: absolute }
.p9V85g{ position: sticky   }
.uK7E77{ position: static   }
.Ude336{ position: fixed    }
/*
    ----------
*/
.x35Ac2{ overflow: visible }
.J057Si{ overflow: hidden  }
.jC732U{ overflow: scroll  }
.L9Rm29{ overflow: auto    }
/*
    ----------
*/
.yL0811{ cursor: alias         }
.v6CN61{ cursor: all-scroll    }
.qE800M{ cursor: auto          }
.tJ204O{ cursor: cell          }
.sDN443{ cursor: col-resize    }
.p8C19l{ cursor: context-menu  }
.E0m26B{ cursor: copy          }
.L567nt{ cursor: crosshair     }
.Ri032C{ cursor: default       }
.kD889h{ cursor: e-resize      }
.S6u0h5{ cursor: ew-resize     }
.c2Q91N{ cursor: grab          }
.dZ9M00{ cursor: grabbing      }
.gM266u{ cursor: help          }
.dP797B{ cursor: move          }
.j3Uh65{ cursor: n-resize      }
.cZ180w{ cursor: ne-resize     }
.aH3174{ cursor: nw-resize     }
.j53Z9D{ cursor: nwse-resize   }
.Gp2446{ cursor: no-drop       }
.Lr9809{ cursor: not-allowed   }
.N4g4m6{ cursor: pointer       }
.k6G6h5{ cursor: progress      }
.Yri682{ cursor: row-resize    }
.Zd4o45{ cursor: s-resize      }
.X2R6j8{ cursor: se-resize     }
.p2M9c8{ cursor: sw-resize     }
.Xw0F20{ cursor: text          }
.Q6j5W4{ cursor: vertical-text }
.S344z0{ cursor: w-resize      }
.z9V38t{ cursor: wait          }
.Ac102a{ cursor: zoom-in       }
.d6F48n{ cursor: zoom-out      }
.h30X35{ cursor: none          }
/*
    ----------
*/
.Kg80G9{ word-wrap: normal     }
.uQ136M{ word-wrap: break-word }
/*
    ----------
*/
.z9X51G{ pointer-events: none }
.Bj1642{ pointer-events: auto }
/*
    ----------
*/
.oQ637t{ border-style: dotted }
.r380oV{ border-style: dashed }
.F5m883{ border-style: solid  }
.Fb292w{ border-style: double }
.Fcx262{ border-style: groove }
.eS845b{ border-style: ridge  }
.L2sq24{ border-style: inset  }
.t3KL23{ border-style: outset }
.Z7o516{ border-style: none   }
.Wx078J{ border-style: hidden }
/*
    ----------
*/
.ob2A34{ border:        0 }
.xq62J3{ border-top:    0 }
.PvN976{ border-bottom: 0 }
.rJ26U8{ border-left:   0 }
.LjJ561{ border-right:  0 }
/*
    ----------
*/
.L72y8b{ border-collapse: collapse }
.Z5y7o1{ border-collapse: separate }
/*
    ----------
*/
.m9X7C5{ margin: 0 auto }
/*
    ----------
*/
.pEO064{ margin:        0 }
.vSV800{ margin-left:   0 }
.D797aR{ margin-right:  0 }
.sX6873{ margin-top:    0 }
.B2d81S{ margin-bottom: 0 }
/*
    ----------
*/
.a527eB{ padding:        0 }
.z8o5S4{ padding-top:    0 }
.dW8M00{ padding-right:  0 }
.C7D19o{ padding-left:   0 }
.R3a5i0{ padding-bottom: 0 }
/*
    ----------
*/
.GO52g1{ border-radius: 0    }
.g03C00{ border-radius: 25%  }
.K79e1C{ border-radius: 50%  }
.pZR189{ border-radius: 75%  }
.kZm977{ border-radius: 100% }
/*
    ----------
*/
.wzU404{ flex: 0 0 auto }
.uTM149{ flex: 1 1 auto }
.U977pl{ flex: 0 1 auto }
.z097Vp{ flex: 1 0 auto }
/*
    ----------
*/
.P1470a{ flex-grow: 0 }
.iw2U41{ flex-grow: 1 }
.I750Xd{ flex-grow: 2 }
.S276Td{ flex-grow: 3 }
/*
    ----------
*/
.X594yq{ flex-basis: 1 }
.U716Tv{ flex-basis: 2 }
.Rzr931{ flex-basis: 3 }
/*
    ----------
*/
.o9T8I8{ flex-wrap: wrap         }
.R0X85t{ flex-wrap: nowrap       }
.Fv9Z90{ flex-wrap: wrap-reverse }
/*
    ----------
*/
.vE6956{ order: 1 }
.XjX424{ order: 2 }
.W85it5{ order: 3 }
/*
    ----------
*/
.Q330w3{ align-content: stretch       }
.v69MS6{ align-content: center        }
.X19jp8{ align-content: space-evenly  }
.v74JJ5{ align-content: flex-start    }
.uH27J4{ align-content: flex-end      }
.T3l2s4{ align-content: space-between }
.D123Qj{ align-content: space-around  }
/*
    ----------
*/
.j1Mr03{ flex-shrink: 0 }
.n7OS97{ flex-shrink: 1 }
/*
    ----------
*/
.Ax30Q1{ box-shadow: none }
/*
    ----------
*/
.J55M9e{ content: "" }
/* 
    ----------
*/
.C4v153{ outline: 0    }
.sD881L{ outline: auto }
/* 
    ----------
*/
.pL4U70{ opacity: 0    }
.q693Lc{ opacity: 25%  }
.aT591i{ opacity: 50%  }
.S8k6H9{ opacity: 75%  }
.aV498I{ opacity: 100% }
/*
    ----------
*/
.cB943h{ -webkit-line-clamp: 2 }
.Bt6232{ -webkit-line-clamp: 3 }
.X2a9R4{ -webkit-line-clamp: 4 }
/*
    ----------
*/
.gDk539{ -webkit-box-orient: block-axis  }
.J4i292{ -webkit-box-orient: inline-axis }
.uQ1c23{ -webkit-box-orient: horizontal  }
.G7o96W{ -webkit-box-orient: vertical    }
/*
    ----------
*/
.c8R6W7{ list-style: decimal-leading-zero }
.WvX121{ list-style: lower-alpha          }
.Iy85Z8{ list-style: lower-greek          }
.X6y6H0{ list-style: lower-latin          }
.xT170D{ list-style: lower-roman          }
.J564hZ{ list-style: upper-alpha          }
.Qd352p{ list-style: upper-latin          }
.Np616D{ list-style: upper-roman          }
.W18b0l{ list-style: disc                 }
.bR099N{ list-style: georgian             }
.Jd2580{ list-style: inside               }
.R723p4{ list-style: outside              }
.dK9146{ list-style: square               }
.h57Y8Q{ list-style: circle               }
.kO539q{ list-style: armenian             }
.vY001R{ list-style: decimal              }
.GnG350{ list-style: none                 }
/*
    ----------
*/
.C196Fv{ writing-mode: horizontal-tb }
.Z95a8x{ writing-mode: vertical-lr   }
.x0F757{ writing-mode: vertical-rl   }
/*
    ----------
*/
.Klc797{ transition: all .8s ease }
/*
    ----------
*/
.t0T2S2{ transform: none           }
.wD3v95{ transform: rotate(90deg)  }
.Zka424{ transform: rotate(180deg) }
.v1M46U{ transform: rotate(270deg) }
.j833Ma{ transform: rotate(360deg) }
/*
    ----------
*/
.jX0924{ object-fit: fill       }
.C0go67{ object-fit: contain    }
.Mg1457{ object-fit: cover      }
.uX61k8{ object-fit: scale-down }
.y60E9p{ object-fit: none       }
/* 
    ----------
*/
.qW182T{ line-height: 1.0 }
.tSz942{ line-height: 1.5 }
.YS535e{ line-height: 2.0 }
/*
    ----------
*/
.SFq502{ background-position: left top      }
.iJM161{ background-position: left center   }
.Gr070F{ background-position: left bottom   }
.Px402I{ background-position: right top     }
.t6K867{ background-position: right center  }
.mG848G{ background-position: right bottom  }
.J2l421{ background-position: center top    }
.F710rg{ background-position: center center }
.vW8q13{ background-position: center bottom }
/*
    ----------
*/
.Tq143x{ background-size: auto    }
.T659Fi{ background-size: cover   }
.B4A44p{ background-size: contain }
/*
    ----------
*/
.P6cE22{ background-repeat: repeat    }
.XcJ938{ background-repeat: repeat-x  }
.rYx754{ background-repeat: repeat-y  }
.N965Go{ background-repeat: no-repeat }
.rG790K{ background-repeat: space     }
.kV124B{ background-repeat: round     }
/*
    ----------
*/
.P779mc{ box-sizing: border-box }
/*
    ----------
*/
.H9g95G{ word-break: normal     }
.kO189r{ word-break: break-all  }
.wK1970{ word-break: keep-all   }
.kL64Z1{ word-break: break-word }
/*
    ----------
*/
.q629Gd{ filter: brightness(0.5)   }
.jSO180{ filter: contrast(160%)     }
.H61jT8{ filter: saturate(3)        }
.wM7o82{ filter: invert(100%)       }
.Q8b7e3{ filter: grayscale(50%)     }
.T354Vs{ filter: sepia(100%)        }
.U029ho{ filter: blur(3px)          }
.o98b8U{ filter: hue-rotate(180deg) }
/*
    ----------
*/
.C23pq8{ resize: both       }
.qS03n2{ resize: none       }
.Ss37t2{ resize: horizontal }
.n3R12l{ resize: vertical   }
/*
    ----------
*/
@mixin Size( $Width, $Height ){ 
    width  : $Width; 
    height : $Height 
}
@mixin Start( $Start, $Top ){ 
    left : $Start; 
    top  : $Top 
}
@mixin End( $End, $Top ){ 
    right : $End; 
    top   : $Top 
}
@mixin Top( $Start, $Bottom ){ 
    left   : $Start; 
    bottom : $Bottom 
}
@mixin Bottom( $End, $Bottom ){ 
    right  : $End; 
    bottom : $Bottom 
}
@mixin Margin( $Top, $End, $Bottom, $Start ){ 
    margin: $Top $End $Bottom $Start 
}
@mixin Padding( $Top, $End, $Bottom, $Start ){ 
    padding: $Top $End $Bottom $Start 
}
/* 
    ----------
*/
@font-face{
    font-family: "GT Walsheim";
    src: url( "./Media/Font/GT-Walsheim/GT-Walsheim-Regular.woff2" ) format( 'woff2' ),
        url( "./Media/Font/GT-Walsheim/GT-Walsheim-Regular.woff" ) format( 'woff' );
    font-weight: 400;
    font-display: swap;
}
@font-face{
    font-family: "GT Walsheim";
    src: url( "./Media/Font/GT-Walsheim/GT-Walsheim-Medium.woff2" ) format( 'woff2' ),
        url( "./Media/Font/GT-Walsheim/GT-Walsheim-Medium.woff" ) format( 'woff' );
    font-weight: 500;
    font-display: swap;
}
@font-face{
    font-family: "GT Walsheim";
    src: url( "./Media/Font/GT-Walsheim/GT-Walsheim-Bold.woff2" ) format( 'woff2' ),
         url( "./Media/Font/GT-Walsheim/GT-Walsheim-Bold.woff" ) format( 'woff' );
    font-weight: 700;
    font-display: swap;
}
/* 
    ----------
*/
html{
    @extend .dE5574;
}
a{
    @extend .v6EU83;
    @extend .vF5k47;
}
a:hover{
    @extend .Q3s2S1;
}
/* 
    ----------
*/
body{
    @extend .dE5574;
    @extend .Wm8G06;
    @extend .rP408N;
}
/* 
    ----------
*/
:root{
    --react-brand    : Agdasima;
}
:root{
    --react-white    : #F1ECE9;
    --react-light    : #F5F9F4;
    --react-simple   : #EEEEEE;
    --react-green    : #162617;
    --react-dark     : #778577;
}
:root{
    --react-small    : 22px;
    --react-medium   : 19.4px;
    --react-large    : 67px;
}
/* 
    ----------
*/
.W5d8a8{
    .Gq784A{
        @include Size( 100%, 44px );
        @extend .gZ959C;
        .Lq451p{
            .vQ72z5{
                .oN1k51{
                    @extend .xG0j09;
                    @extend .vOx602;
                    @extend .nS533w;
                    line-height: 44px;
                }
                .nU170e{
                    @extend .xG0j09;
                    @extend .vOx602;
                    @extend .nS533w;
                    @extend .Klc797;
                    @extend .C4v153;
                    line-height: 44px;
                }
            }
        }
    }
    .Aq134e{
        @include Size( 100%, 70px );
        @extend .u3I753;
        @extend .cH8711;
        .Dc4872{
            @extend .cH8711;
            .Kyv302{
                @include Size( 100%, 70px );
                @extend .GnG350;
                li{
                    a{
                        @extend .pEO064;
                        @extend .Wm8G06;
                        @extend .vF5k47;
                        @extend .Klc797;
                        padding: 0 19px;
                        color: var(--react-dark);
                        &:hover{
                            @extend .M99v3q;
                        }
                    }
                    &:first-child a{
                        @extend .C7D19o;
                    }
                    &:last-child a{
                        @extend .dW8M00;
                    }
                }
            }
            .aI9762{
                @include Size( 100%, 70px );
                border-left: 1px solid rgba( map-get( $Color, "White" ), .05 );
                .TqL842{
                    @extend .Klc797;
                    @extend .C4v153;
                    @extend .Wm8G06;
                    @extend .Q3s2S1;
                    @extend .cB3332;
                    color: var(--react-dark);
                    &:hover{
                        @extend .M99v3q;
                    }
                }
            }
        }
        .Km40p1{
            @include Size( 70px, 70px );
            @extend .u0Bc59;
            .Be06b8{
                .Px180R{
                    @include Size( 100%, 70px );
                    @extend .Klc797;
                    @extend .C4v153;
                    svg{
                        @include Size( 19px, 19px );
                        @extend .M99v3q;
                    }
                }
            }
        }
    }
    .G27q6Y{
        @include Size( 100%, 70px );
        @extend .C4v153;
        @extend .Klc797;
        .A4e723{
            @include Size( 36px, 36px );
            svg{
                @include Size( 36px, 36px );
                @extend .cvC365;
            }
        }
        .v034YD{
            @extend .K0cW96;
            @extend .Klc797;
            font-size: map-get( $Size, "20" ) + 8px;
            font-family: var(--react-brand);
            color: var(--react-dark);
        }
        &:hover .v034YD{
            @extend .M99v3q;
        }
    }
}
/* 
    ----------
*/
.wM890a{
    .v4O89C{
        @include Size( 100%, 768px );
        @extend .cH8711;
        .uN459e{
            @include Size( 100%, 768px );
            @extend .cH8711;
            z-index: 12;
            .B371q0{
                @extend .M99v3q;
                @extend .D933nr;
                font-size: var(--react-large);
                line-height: 77px;
            }
            .S8a89c{
                @extend .nS533w;
                font-size: var(--react-medium);
                color: var(--react-dark);
                line-height: 32px;
                &::before{
                    content: "Erzurum'da Bahçe Sulama Sistemleri, Damlama Sulama Sistemleri, Otomatik Sulama Sistemleri Hizmetleri Sunulur."
                }
            }
            .W4g512{
                @include Size( 220px, 48px );
                @extend .u3I753;
                @extend .ob2A34;
                @extend .Klc797;
                @extend .C4v153;
                color: var(--react-dark);
                &:hover{
                    @extend .u0Bc59;
                    @extend .M99v3q;
                }
            }
            .d6J923{
                @extend .Wm8G06;
                @extend .K0cW96;
                @extend .Q3s2S1;
                @extend .cB3332;
                color: var(--react-dark);
                line-height: 48px;
            }
        }
        &::before{
            @include Size( 50%, 768px );
            @extend .J55M9e;
            @extend .u3I753;
            @extend .T659Fi;
            @extend .F710rg;
            @extend .N965Go;
            @extend .S6pt64;
            @extend .q629Gd;
            background-image: url( "https://media.suciler.com/featured/001.jpg" );
        }
        &::after{
            @include Size( 50%, 768px );
            @include End( 0, 0 );
            @extend .J55M9e;
            @extend .yJ452A;
            @extend .gBt584;
            background-color: var(--react-green);
        }
    }
    .x46W3m{
        @extend .cH8711;
        .A49o37{
            @include Size( 100%, 110px );
            @extend .u0Bc59;
            @extend .a527eB;
            margin: -55px 0 0 0;
            .dS2q53{
                @extend .cH8711;
                .Un2v98{
                    @extend .M99v3q;
                    @extend .K0cW96;
                    font-size: map-get( $Size, "20" ) + 10px;
                    line-height: 110px
                }
                .F62fz9{
                    @extend .J589Ta;
                    @extend .M99v3q;
                    line-height: 110px
                }
            }
        }
    }
    .Wy021B{
        @include Size( 100%, auto );
        @extend .cH8711;
        .Ef0d31{
            @include Size( 100%, 636px );
            .hG439F{
                @extend .vOx602;
                @extend .K0cW96;
                font-size: map-get( $Size, "20" ) + 27px;
                line-height: 52px;
            }
            .C3h5b8{
                @extend .J589Ta;
                @extend .Y0u334;
                @extend .nS533w;
                line-height: 30px;
            }
            .A98nj3{
                @extend .s4U1j1;
                @extend .Y0u334;
                @extend .nS533w;
                @extend .Q3s2S1;
                @extend .cB3332;
                @extend .Klc797;
                line-height: 25px;
                svg{
                    @include Size( 15px, 15px );
                    margin: 0 0 0 5px;
                }
                &:hover{
                    @extend .vOx602;
                }
            }
        }
        &::before{
            @include Start( 0, 0 );
            @include Size( 50%, auto );
            @extend .J55M9e;
            @extend .S6pt64;
            @extend .gBt584;
            background: var(--react-light);
        }
        .T4nG47{
            @extend .cH8711;
            .fa66F7{
                @include Size( 50%, auto );
                @extend .T659Fi;
                @extend .F710rg;
                @extend .N965Go;
                video{
                    @include Size( 100%, auto );
                }
            }
            video{
                @include Size( 100%, auto );
            }
        }
    }
    .m0N07k{
        @extend .cH8711;
        .yJ329g{
            .j5D7Y1{
                .Vk449i{
                    @include Size( 100%, 206px );
                    background: var(--react-light);
                }
                .iY070W{
                    @include Size( 100%, auto );
                    .Hhu256{
                        @include Size( 100%, 158px );
                        @extend .rP408N;
                        @extend .a527eB;
                        margin: -100px 0 0 0;
                        img{
                            @include Size( 100%, auto );
                        }
                    }
                }
                .qK0P64{
                    .eO7d15{
                        @extend .rP408N;
                        margin: -50px 0 0 0;
                    }
                    .Ut557S{
                        @extend .s4U1j1;
                        @extend .vOx602;
                        line-height: 26px
                    }
                }
                &:hover .Ut557S{
                    @extend .Q3s2S1;
                }
            }
        }
    }
    .wN0r67{
        @include Size( 100%, 550px );
        @extend .gZ959C;
        @extend .T659Fi;
        @extend .F710rg;
        @extend .N965Go;
        background-image: url( "./Media/Image/002.jpg" )
    }
    .Lr88Q6{
        .Po583d{
            @extend .a527eB;
            @extend .rP408N;
            margin: -75px 0 0 0;
            box-shadow: 0 5px 15px rgba( map-get( $Color, "Black" ), 0.08 );
            .dT3840{
                @include Size( 100%, auto );
                img{
                    @include Size( 100%, auto );
                }
            }
            .Lx375Z{
                @extend .rP408N;
                min-height: 150px;
                .e8A61l{
                    @extend .vOx602;
                    @extend .i260Up;
                    border-bottom: 1px solid var(--react-white);
                    line-height: 28px
                }
                .j84N4b{
                    @extend .bS8S40;
                    @extend .Y0u334;
                    line-height: 25px
                }
                .F70ce3{
                    @extend .bS8S40;
                    @extend .Y0u334;
                    @extend .Q3s2S1;
                    @extend .cB3332;
                    @extend .Klc797;
                    line-height: 25px;
                    &:hover{
                        @extend .vOx602;
                    }
                }
            }
        }
    }
    .j94HK3{
        @include Size( 50%, 631px );
        @extend .cH8711;
        .Zf493o{
            @include Size( 50%, 631px );
            .Xj06u6{
                @extend .cH8711;
                z-index: 15;
                .J022yx{
                    @extend .vOx602;
                    @extend .K0cW96;
                    font-size: map-get( $Size, "20" ) + 16px;
                    line-height: 42px
                }
                .yZ9a75{
                    @extend .Y0u334;
                    @extend .nS533w;
                    font-size: map-get( $Size, "20" ) + 1px;
                    line-height: 32px
                }
            }
            .p8M5k8{
                @include Size( 100%, 48px );
                @extend .gZ959C;
                @extend .ob2A34;
                @extend .Klc797;
                @extend .C4v153;
                @extend .Wm8G06;
                @extend .vOx602;
                @extend .cH8711;
                z-index: 15;
                &::placeholder{
                    @extend .aV498I;
                    @extend .Y0u334;
                }
            }
            .Rf4458{
                @include Size( 100%, 120px );
                @extend .gZ959C;
                @extend .ob2A34;
                @extend .Klc797;
                @extend .C4v153;
                @extend .Wm8G06;
                @extend .Y0u334;
                @extend .cH8711;
                z-index: 15;
                &::placeholder{
                    @extend .aV498I;
                    @extend .Y0u334;
                }
            }
            .Tg352A{
                @include Size( 180px, 48px );
                @extend .u0Bc59;
                @extend .ob2A34;
                @extend .Klc797;
                @extend .C4v153;
                @extend .Wm8G06;
                @extend .M99v3q;
                @extend .cH8711;
                line-height: 48px;
                z-index: 15;
                &:hover{
                    @extend .u3I753;
                }
            }
            .Tlu297{
                @extend .Wm8G06;
                @extend .vOx602;
                @extend .nS533w;
                z-index: 12;
            }
        }
        &::before{
            @include Start( 0, 0 );
            @include Size( 50%, 631px );
            @extend .J55M9e;
            @extend .T659Fi;
            @extend .F710rg;
            @extend .N965Go;
            @extend .S6pt64;
            @extend .gZ959C;
            @extend .gBt584;
            background-image: url( "./Media/Image/004.jpg" )
        }
        &::after{
            @include Size( 50%, 631px );
            @include End( 0, 0 );
            @extend .gBt584;
            @extend .yJ452A;
            @extend .J55M9e;
            background-image: -webkit-linear-gradient( 90deg, map-get( $Color, "White" ) 0%, map-get( $Color, "Light" ) 100% );
        }
    }
    .bQ94U5{
        .T1Gj62{
            @include Size( 100%, 120px );
            border-top: 1px solid var(--react-simple);
            .Jg006P{
                @extend .Wm8G06;
                @extend .vOx602;
                @extend .K0cW96;
            }
            .oH646D{
                @extend .wI860z;
                @extend .Y0u334;
                @extend .y3Or88;
                line-height: 23px
            }
        }
    }
}
/* 
    ----------
*/
.Tv84y2{
    .p7E5W8{
        @include Size( 100%, 55px );
        background: var(--react-green);
        .o22gA8{
            @extend .GnG350;
            li{
                a{
                    @extend .Wm8G06;
                    color: var(--react-dark);
                    line-height: 55px
                }
                label{
                    @extend .Klc797;
                    line-height: 55px;
                    svg{
                        @include Size( 14px, 14px );
                        color: var(--react-dark);
                    }
                }
                span{
                    @extend .Wm8G06;
                    color: var(--react-dark);
                    line-height: 55px
                }
            }
        }
    }
    .pM13z9{
        @include Size( 100%, 500px );
        @extend .gZ959C;
        @extend .T659Fi;
        @extend .F710rg;
        @extend .N965Go;
        @extend .cH8711;
        background-image: url( "./Media/Image/002.jpg" );
        &::before{
            @include Start( 0, 0 );
            @include Size( 100%, 500px );
            @extend .J55M9e;
            @extend .gBt584;
            @extend .S6pt64;
            background-color: rgba( map-get( $Color, "Black" ), .6 );
        }
    }
    .r9714B{
        @include Size( 100%, 600px );
        @extend .cH8711;
        @extend .u3I753;
        max-width: 350px;
        z-index: 9998;
        .Pr7119{
            @include Size( 100%, 60px );
            @extend .ob2A34;
            @extend .Klc797;
            @extend .C4v153;
            @extend .Wm8G06;
            @extend .M99v3q;
            @extend .u0Bc59;
            svg{
                @include Size( 16px, 16px );
                @extend .M99v3q;
                margin: 4.5px 0 0 0;
            }
        }
        .Cs75y0{
            @extend .GnG350;
            li{
                a{
                    @include Size( 100%, 48px );
                    @extend .Klc797;
                    @extend .Wm8G06;
                    @extend .vOx602;
                    color: var(--react-dark);
                    line-height: 48px;
                    svg{
                        @include Size( 15px, 15px );
                        margin: 2px 0 0 0;
                    }
                    &:hover{
                        @extend .vF5k47;
                        background: var(--react-green);
                    }
                }
                .Active{
                    background: var(--react-green);
                }
            }
        }
    }
    .S7j79P{
        z-index: 11;
        .Sm2277{
            @extend .a527eB;
            margin: -100px 0 0 0;
            .uP71E5{
                @extend .gZ959C;
                @extend .vOx602;
                @extend .K0cW96;
                font-size: map-get( $Size, "20" ) + 12px;
                line-height: 41px;
            }
            .Ur628J{
                box-shadow: 1.95px 1.95px 2.6px rgba( 27, 27, 27, .15 );
                p{
                    @extend .S6pt64;
                    @extend .bS8S40;
                    @extend .vOx602;
                    @extend .nS533w;
                    @extend .C96Q4r;
                    margin: 0 0 15px;
                    line-height: 28px;
                }
                .pT454Q{
                    @extend .dK9146;
                    li{
                        @include Size( 33.3%, auto );
                        span{
                            @extend .bS8S40;
                            @extend .vOx602;
                            line-height: 28px
                        }
                    }
                }
                .xP179v{
                    @include Size( 100%, 1px );
                    background: var(--react-simple);
                }
                .N03l2x{
                    .Pi081r{
                        @extend .s4U1j1;
                        @extend .vOx602;
                        @extend .K0cW96;
                        line-height: 30px;
                    }
                    .iT20I3{
                        @extend .bS8S40;
                        @extend .vOx602;
                        @extend .nS533w;
                        line-height: 27px;
                    }
                    .ND5r57{
                        @extend .bS8S40;
                        @extend .vOx602;
                        @extend .nS533w;
                        @extend .Q3s2S1;
                        @extend .cB3332;
                        line-height: 27px;
                    }
                }
                .Wy378q{
                    .k6YS15{
                        @include Size( 100%, auto );
                        @extend .gZ959C;
                        img{
                            @include Size( 100%, auto );
                        }
                    }
                    .K19dR4{
                        @extend .bS8S40;
                        @extend .vOx602;
                        @extend .nS533w;
                        line-height: 30px
                    }
                }
                .Pi544T{
                    @include Size( 180px, 48px );
                    @extend .u0Bc59;
                    @extend .ob2A34;
                    @extend .Klc797;
                    @extend .C4v153;
                    @extend .Wm8G06;
                    @extend .M99v3q;
                    @extend .nS533w;
                    line-height: 48px;
                    &:hover{
                        @extend .u3I753;
                    }
                }
                .Vh322X{
                    .r6IP51{
                        @include Size( 100%, 154px );
                        @extend .N4g4m6;
                        img{
                            @include Size( 100%, auto );
                        }
                    }
                }
                .Up18V9{
                    .Zg04t3{
                        .Dz7328{
                            @include Size( 100%, auto );
                            img{
                                @include Size( 100%, auto );
                            }
                        }
                        .gB956B{
                            @extend .vOx602;
                            @extend .Wm8G06;
                            @extend .nS533w;
                            line-height: 30px
                        }
                    }
                }
                .row .col-md-1{
                    @include Size( 20%, auto );
                }
            }
            .zJ415U{
                background: var(--react-light);
                .C2830W{
                    @extend .gZ959C;
                    @extend .vOx602;
                    @extend .K0cW96;
                    font-size: map-get( $Size, "20" ) + 12px;
                    line-height: 41px;
                }
                .jJ307S{
                    .N1g26Z{
                        .Px42o4{
                            @include Size( 100%, 48px );
                            @extend .gZ959C;
                            @extend .vOx602;
                            @extend .ob2A34;
                            @extend .Wm8G06;
                            @extend .Klc797;
                            @extend .C4v153;
                            &::placeholder{
                                @extend .aV498I;
                            }
                        }
                        .P3Q1n6{
                            @include Size( 100%, 48px );
                            @extend .vOx602;
                            @extend .ob2A34;
                            @extend .Wm8G06;
                            @extend .Klc797;
                            @extend .C4v153;
                            background: url( "data:image/svg+xml;charset=utf-8,%3Csvg height='12' width='12' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E" ) no-repeat;
                            background-position: calc(100% - 15px);
                            appearance: none;
                            background-color: map-get( $Color, "Light" );
                            &::placeholder{
                                @extend .aV498I;
                            }
                        }
                        .hR684S{
                            @include Size( 100%, 150px );
                            @extend .gZ959C;
                            @extend .vOx602;
                            @extend .ob2A34;
                            @extend .Wm8G06;
                            @extend .Klc797;
                            @extend .C4v153;
                            &::placeholder{
                                @extend .aV498I;
                            }
                        }
                        .uI93C5{
                            @include Size( 100%, 54px );
                            @extend .u0Bc59;
                            @extend .ob2A34;
                            @extend .Klc797;
                            @extend .C4v153;
                            @extend .M99v3q;
                            @extend .Wm8G06;
                            &:hover{
                                @extend .u3I753;
                            }
                        }
                        .mG3J91{
                            @extend .Wm8G06;
                            @extend .vOx602;
                            @extend .nS533w;
                            line-height: 24px
                        }
                    }
                }
            }
        }
    }
}
/* 
    ----------
*/
.J5bv36{
    @include Size( 100%, 100vh );
    background-color: rgba( map-get( $Color, "Black" ), .7 );
    z-index: 9999;
    .A0v6a5{
        @extend .cH8711;
        .pB7g34{
            @extend .rP408N;
            img{
                @include Size( 100%, auto );
                max-width: 400px
            }
        }
        .gF5x66{
            @extend .rP408N;
            min-width: 500px;
            max-width: 500px;
            .Mu65h3{
                .e6G973{
                    @include Size( 100%, 70px );
                    @extend .i260Up;
                    @extend .vOx602;
                    @extend .K0cW96;
                    @extend .gZ959C;
                    line-height: 70px
                }
                .wB264Y{
                    @extend .Wm8G06;
                    @extend .vOx602;
                    @extend .nS533w;
                    border-bottom: 1px solid var(--react-simple);
                    line-height: 24px
                }
                .Dl412q{
                    @extend .GnG350;
                    li{
                        span{
                            @extend .Wm8G06;
                            @extend .vOx602;
                            @extend .nS533w;
                            line-height: 30px
                        }
                    }
                }
            }
            img{
                @include Size( 100%, auto );
            }
        }
        .xT8051{
            @include Size( 32px, 32px );
            @extend .u0Bc59;
            @extend .ob2A34;
            @extend .Klc797;
            @extend .C4v153;
            @extend .a527eB;
            margin: -16px -16px 0 0;
            svg{
                @include Size( 18px, 18px );
                @extend .M99v3q;
            }
        }
    }
}
.F3G3f8{
    @include Start( 0, 114px );
    @include Size( 100%, 100vh );
    background: var(--react-green);
    z-index: 9999;
    .n5G5v5{
        .B14l1O{
            @extend .Wm8G06;
            @extend .M99v3q;
            @extend .nS533w;
        }
        .rF382o{
            @extend .GnG350;
            li{
                a{
                    @extend .wI860z;
                    @extend .nS533w;
                    color: var(--react-dark);
                    line-height: 27px
                }
            }
        }
    }
    .col-md-2{
        @include Size( 12.5%, auto );
    }
}
/* 
    ----------
*/
.Zp8333{
    @extend .cH8711;
    .Q2tC03{
        @include Size( 100%, auto );
        @extend .cH8711;
        background: var(--react-green);
        &::before{
            @include Start( 0, 0 );
            @include Size( 70%, auto );
            @extend .J55M9e;
            @extend .u3I753;
            @extend .S6pt64;
            @extend .gBt584;
        }
        .M6q15K{
            @extend .cH8711;
            .Xm789J{
                .Cp86l9{
                    .Ed843m{
                        @extend .Wm8G06;
                        @extend .M99v3q;
                        @extend .nS533w;
                        @extend .cH8711;
                    }
                    .qI2851{
                        @extend .GnG350;
                        li{
                            a{
                                @extend .wI860z;
                                @extend .nS533w;
                                color: var(--react-dark);
                                line-height: 27px
                            }
                        }
                    }
                }
                .col-md-3{ 
                    @include Size( 20%, auto );
                }
            }
            .xM547x{
                .oE11e4{
                    @extend .GnG350;
                    border-top: 1px solid rgba( map-get( $Color, "White" ), .08 );
                    li{
                        a{
                            @include Size( auto, 33px );
                            @extend .Klc797;
                            @extend .PlO170;
                            color: var(--react-dark);
                            margin: 35px 0 0 22px;
                            line-height: 33px;
                            border: 1px solid rgba( map-get( $Color, "White" ), .05 );
                            border-radius: 3px;
                            &:hover{
                                @extend .u0Bc59;
                                @extend .vF5k47;
                                @extend .M99v3q;
                            }
                        }
                        &:first-child a{
                            @extend .vSV800;
                        }
                    }
                }
            }
            .j4GI73{
                .u77f0W{
                    @extend .Wm8G06;
                    @extend .M99v3q;
                    @extend .nS533w;
                    @extend .cH8711;
                }
                .Ay4275{
                    @extend .wI860z;
                    @extend .nS533w;
                    color: var(--react-dark);
                    line-height: 26px
                }
                .n1G33K{
                    @extend .wI860z;
                    @extend .nS533w;
                    color: var(--react-dark);
                    @extend .Q3s2S1;
                    text-underline-offset: 10px;
                    line-height: 22px
                }
            }
        }
    }
    .k875GB{
        @include Size( 100%, 80px );
        @extend .gZ959C;
        .Vs816m{
            .b8yT11{
                @extend .Wm8G06;
                @extend .Y0u334;
                @extend .nS533w;
                line-height: 80px
            }
        }
        .Z12c9K{
            @include Size( 100%, 80px );
            .Kqh992{
                @extend .Wm8G06;
                @extend .Y0u334;
                @extend .nS533w;
                @extend .Klc797;
                @extend .C4v153;
                svg{
                    @include Size( 11px, 11px );
                    margin: -1px 0 0 3px;
                }
            }
        }
    }
    .A6u093{
        @include Start( 0, -37px );
        @include Size( 80px, 550px );
        @extend .u0Bc59;
        .Ez67s6{
            @include Size( 100%, 550px );
            @extend .cH8711;
            .YZm645{
                @include Size( 100%, 100% );
                svg{
                    @include Size( 24px, 24px );
                    @extend .M99v3q;
                }
            }
        }
    }
}
/* 
    ----------
*/
.Uc48Q7{
    @include Size( 100%, 55px );
    @extend .u3I753;
    @extend .hPP928;
    .T7Vz96{
        @include Size( 100%, 55px );
        @extend .Klc797;
        @extend .C4v153;
        @extend .pEO064;
        @extend .a527eB;
        @extend .cH8711;
        z-index: 11;
        svg{
            @include Size( 24px, 24px );
        }
    }
    .TH4u73{
        @include Size( 100%, 55px );
        @extend .Klc797;
        @extend .C4v153;
        @extend .pEO064;
        @extend .a527eB;
        @extend .cH8711;
        z-index: 11;
        svg{
            @include Size( 24px, 24px );
        }
    }
    .W7gM58{
        @include Size( 100%, 55px );
        @extend .Klc797;
        @extend .C4v153;
        @extend .pEO064;
        @extend .a527eB;
        @extend .cH8711;
        z-index: 11;
        svg{
            @include Size( 20px, 20px );
            path{
                @extend .f8T6I8;
            }
        }
    }
    .w0B656{
        @include Size( 100%, 55px );
        @extend .Klc797;
        @extend .C4v153;
        @extend .pEO064;
        @extend .a527eB;
        @extend .cH8711;
        @extend .M99v3q;
        font-family: var(--react-brand);
        font-size: var(--react-small);
        svg{
            @include Size( 28px, 28px );
            @extend .cvC365;
        }
    }
    .col-md-2{
        @include Size( 16.66666667%, auto );
    }
    .col-md-8{
        @include Size( 66.66666667%, auto );
    }
}
.Tl356z{
    @include Start( 0, 99px );
    @include Size( 100%, 100vh );
    @extend .hPP928;
    background: var(--react-green);
    z-index: 9999;
    .Tj682G{
        @include Size( 100%, 100vh );
        @extend .GnG350;
        li{
            a{
                @include Size( 100%, 50px );
                @extend .Klc797;
                @extend .Wm8G06;
                color: var(--react-dark);
                border-bottom: 1px solid rgba( map-get( $Color, "White" ), 0.03 );
                line-height: 50px;
                &:hover{
                    @extend .vF5k47;
                    @extend .M99v3q;
                }
                &:hover svg path{
                    @extend .Klc797;
                    @extend .f8T6I8;
                }
            }
            svg{
                @include Size( 16px, 16px );
                @extend .Klc797;
                margin: 5px 0 0 0;
            }
            &:last-child a{
                @extend .ob2A34;
            }
        }
    }
}
.vl4W39{
    @extend .hPP928;
    .a4U348{
        @include Size( 100%, 55px );
        @extend .u3I753;
        z-index: 99;
        .tI518x{
            @include Size( 100%, 55px );
            @extend .Klc797;
            @extend .C4v153;
            @extend .pEO064;
            @extend .a527eB;
            svg{
                @include Size( 19px, 19px );
                path{
                    @extend .f8T6I8;
                }
            }
        }
        .col-md-3{
            @include Size( 25%, auto );
        }
    }
}