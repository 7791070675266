@media (min-width: 1280px) and (max-width: 1281px){}
@media (min-width: 1024px) and (max-width: 1279px){
    .Dc4872 .container .row .col-md-3{ display: none }
    .Dc4872 .container .row .col-md-7{ width: 83.33333333% }
    .Kyv302{ justify-content: center!important }
    .Kyv302 li a{ padding: 0 13px!important }
    .Kyv302 li:first-child a{ padding-left: 0!important }
    .Kyv302 li:last-child a{ padding-right: 0!important }
    .A4e723 svg{ width: 34px!important }
    .A4e723 svg{ height: 34px!important }
    .v034YD{ font-size: 25px!important }
    .v4O89C{ height: auto!important }
    .v4O89C{ background: var(--react-green) }
    .v4O89C::before{ height: auto!important }
    .v4O89C::before{ padding-top: 50%!important }
    .v4O89C::before{ display: block!important }
    .uN459e{ height: auto!important }
    .uN459e{ min-height: 512px!important }
    .B371q0 br{ display: none!important }
    .v4O89C::after, .Ef0d31{ height: auto!important }
    .v4O89C::after{ min-height: 512px }
    .B371q0{ font-size: 46px!important }
    .B371q0{ line-height: 58px!important }
    .S8a89c::before{ content: "Erzurum'da Bahçe Sulama Sistemleri Hizmetleri Sunulmaktadır."!important }
    .S8a89c{ font-size: 18.5px!important }
    .S8a89c{ line-height: 30px!important }
    .x46W3m .col-md-8{ width: 90%!important }
    .Un2v98{ font-size: 24px!important }
    .F62fz9{ font-size: 18px!important }
    .F62fz9{ line-height: 115px!important }
    .Wy021B::before{ height: auto!important }
    .hG439F br{ display: none!important }
    .hG439F{ font-size: 36px!important }
    .hG439F{ line-height: 46px!important }
    .Ef0d31{ min-height: 436px!important }
    .C3h5b8{ font-size: 18px!important }
    .C3h5b8 br{ display: none!important }
    .C3h5b8{ line-height: 28px!important }
    .Wy021B, .fa66F7{ height: auto!important }
    .Vk449i, .wN0r67, .j94HK3::before, .j94HK3, .Zf493o{ height: auto!important }
    .wN0r67, .j94HK3::before{ display: block!important }
    .wN0r67, .j94HK3::before{ padding-top: 50%!important }
    .e8A61l{ font-size: 18px!important }
    .j84N4b{ font-size: 16px!important }
    .j84N4b{ line-height: 24px!important }
    .J022yx{ font-size: 31px!important }
    .J022yx{ line-height: 38px!important }
    .yZ9a75{ font-size: 18px!important }
    .yZ9a75, .Ed843m{ overflow: hidden }
    .yZ9a75, .Ed843m{ white-space: nowrap }
    .yZ9a75, .Ed843m{ text-overflow: ellipsis }
    .yZ9a75{ line-height: 24px!important }
    .Xj06u6{ margin: 0!important }
    .Xm789J .col-md-3:nth-child(3){ display: none!important }
    .Xm789J .col-md-3{ width: 25%!important }
    .Ay4275{ line-height: 24px!important }
    .xM547x{ display: none!important }
    .A6u093{ top: 0!important }
    .A6u093{ height: 100%!important }
    .r9714B{ display: none!important }
    .S7j79P{ position: relative!important }
    .S7j79P .offset-md-3{ margin-left: 0!important }
    .S7j79P .col-md-9{ width: 100%!important }
    .pM13z9{ height: auto!important }
    .pM13z9{ display: block!important }
    .pM13z9{ padding-top: 50%!important }
    .pM13z9{ width: 100%!important }
}
@media screen and (max-width: 1024px){
    .v4O89C::before, .v4O89C, .uN459e, .Wy021B, .Wy021B::before{ height: auto!important }
    .v4O89C::before{ display: block!important }
    .v4O89C::before{ padding-top: 50%!important }
}
@media (min-width: 801px) and (max-width: 991.2px){
    .Dc4872 .container .row .col-md-3{ display: none }
    .Dc4872 .container .row .col-md-7{ width: 83.33333333% }
    .Kyv302{ justify-content: end!important }
    .Kyv302 li a{ padding: 0 10px!important }
    .Kyv302 li:first-child a{ padding-left: 0!important }
    .Kyv302 li:last-child a{ padding-right: 0!important }
    .Kyv302 li:first-child a{ display: none!important }
    .v034YD{ font-size: 23px!important }
    .B371q0 br{ display: none!important }
    .v4O89C::before{ width: 100%!important }
    .v4O89C::after{ width: auto!important }
    .v4O89C::after{ height: auto!important }
    .v4O89C::before, .v4O89C::after{ position: relative!important }
    .uN459e{ padding: 0!important }
    .v4O89C{ background: var(--react-green) }
    .uN459e{ padding: 30px 0!important }
    .B371q0{ font-size: 48px!important }
    .B371q0{ line-height: 60px!important }
    .x46W3m .col-md-8{ width: 100%!important }
    .A49o37{ margin: 25px 0 0 0!important }
    .Un2v98{ font-size: 26px!important }
    .F62fz9{ font-size: 18px!important }
    .F62fz9{ line-height: 113px!important }
    .A49o37 .col-md-4:last-child{ display: none!important }
    .A49o37 .col-md-4{ width: 50%!important }
    .Wy021B::before{ width: 100%!important }
    .Wy021B .col-md-6{ width: 100%!important }
    .T4nG47{ padding: 0!important }
    .hG439F br{ display: none!important }
    .hG439F{ font-size: 36px!important }
    .hG439F{ line-height: 45px!important }
    .C3h5b8{ line-height: 31px!important }
    .Ef0d31{ height: auto!important }
    .fa66F7{ height: auto!important }
    .hG439F{ margin: 25px 0 0 0!important }
    .Vk449i{ height: auto!important }
    .yJ329g{ margin: 40px 0 0 0!important }
    .wN0r67{ height: auto!important }
    .wN0r67{ display: block!important }
    .wN0r67{ padding-top: 50%!important }
    .wN0r67{ width: 100%!important }
    .Lr88Q6 .col-md-4:last-child{ display: none }
    .Lr88Q6 .col-md-4{ width: 50%!important }
    .j84N4b{ font-size: 16px!important }
    .e8A61l{ font-size: 18px!important }
    .j94HK3{ height: auto!important }
    .j94HK3::before, .j94HK3::after{ height: auto!important }
    .j94HK3::before{ display: block!important }
    .j94HK3::before{ padding-top: 50%!important }
    .j94HK3::before{ width: 100%!important }
    .Zf493o{ height: auto!important }
    .j94HK3 .offset-md-6{ margin-left: 0!important }
    .j94HK3 .col-md-6{ width: 100%!important }
    .j94HK3::before, .j94HK3::after{ position: relative!important }
    .J022yx{ font-size: 32px!important }
    .J022yx{ line-height: 40px!important }
    .Xj06u6{ margin: 0!important }
    .Zf493o{ padding-top: 30px!important }
    .Zf493o .row .col-md-6{ width: 50%!important }
    .Q2tC03::before{ width: 100%!important }
    .Q2tC03::before{ position: relative!important }
    .Q2tC03 .col-md-9{ width: 100%!important }
    .Ed843m{ overflow: hidden }
    .Ed843m{ white-space: nowrap }
    .Ed843m{ text-overflow: ellipsis }
    .xM547x{ display: none!important }
    .j4GI73{ display: none!important }
    .A6u093{ top: 0!important }
    .A6u093{ height: 100%!important }
    .r9714B{ display: none!important }
    .S7j79P{ position: relative!important }
    .S7j79P .offset-md-3{ margin-left: 0!important }
    .S7j79P .col-md-9{ width: 100%!important }
    .pM13z9{ height: auto!important }
    .pM13z9{ display: block!important }
    .pM13z9{ padding-top: 50%!important }
    .pM13z9{ width: 100%!important }
}
@media (min-width: 720px) and (max-width: 800px){
    .Dc4872 .container .row .col-md-3{ display: none }
    .Dc4872 .container .row .col-md-7{ width: 83.33333333% }
    .Kyv302{ justify-content: end!important }
    .Kyv302 li a{ padding: 0 10px!important }
    .Kyv302 li:first-child a{ padding-left: 0!important }
    .Kyv302 li:last-child a{ padding-right: 0!important }
    .Kyv302 li:first-child a{ display: none!important }
    .v034YD{ font-size: 23px!important }
    .B371q0 br{ display: none!important }
    .v4O89C::before{ width: 100%!important }
    .v4O89C::after{ width: auto!important }
    .v4O89C::after{ height: auto!important }
    .v4O89C::before, .v4O89C::after{ position: relative!important }
    .uN459e{ padding: 0!important }
    .v4O89C{ background: var(--react-green) }
    .uN459e{ padding: 30px 0!important }
    .B371q0{ font-size: 48px!important }
    .B371q0{ line-height: 60px!important }
    .x46W3m .col-md-8{ width: 100%!important }
    .A49o37{ margin: 25px 0 0 0!important }
    .Un2v98{ font-size: 26px!important }
    .F62fz9{ font-size: 18px!important }
    .F62fz9{ line-height: 113px!important }
    .A49o37 .col-md-4:last-child{ display: none!important }
    .A49o37 .col-md-4{ width: 50%!important }
    .Wy021B::before{ width: 100%!important }
    .Wy021B .col-md-6{ width: 100%!important }
    .T4nG47{ padding: 0!important }
    .hG439F br{ display: none!important }
    .hG439F{ font-size: 36px!important }
    .hG439F{ line-height: 45px!important }
    .C3h5b8{ line-height: 31px!important }
    .Ef0d31{ height: auto!important }
    .fa66F7{ height: auto!important }
    .hG439F{ margin: 25px 0 0 0!important }
    .Vk449i{ height: auto!important }
    .yJ329g{ margin: 40px 0 0 0!important }
    .wN0r67{ height: auto!important }
    .wN0r67{ display: block!important }
    .wN0r67{ padding-top: 50%!important }
    .wN0r67{ width: 100%!important }
    .Lr88Q6 .col-md-4:last-child{ display: none }
    .Lr88Q6 .col-md-4{ width: 50%!important }
    .j84N4b{ font-size: 16px!important }
    .e8A61l{ font-size: 18px!important }
    .j94HK3{ height: auto!important }
    .j94HK3::before, .j94HK3::after{ height: auto!important }
    .j94HK3::before{ display: block!important }
    .j94HK3::before{ padding-top: 50%!important }
    .j94HK3::before{ width: 100%!important }
    .Zf493o{ height: auto!important }
    .j94HK3 .offset-md-6{ margin-left: 0!important }
    .j94HK3 .col-md-6{ width: 100%!important }
    .j94HK3::before, .j94HK3::after{ position: relative!important }
    .J022yx{ font-size: 32px!important }
    .J022yx{ line-height: 40px!important }
    .Xj06u6{ margin: 0!important }
    .Zf493o{ padding-top: 30px!important }
    .Zf493o .row .col-md-6{ width: 50%!important }
    .Q2tC03::before{ width: 100%!important }
    .Q2tC03::before{ position: relative!important }
    .Q2tC03 .col-md-9{ width: 100%!important }
    .Ed843m{ overflow: hidden }
    .Ed843m{ white-space: nowrap }
    .Ed843m{ text-overflow: ellipsis }
    .xM547x{ display: none!important }
    .j4GI73{ display: none!important }
    .A6u093{ top: 0!important }
    .A6u093{ height: 100%!important }
    .r9714B{ display: none!important }
    .S7j79P{ position: relative!important }
    .S7j79P .offset-md-3{ margin-left: 0!important }
    .S7j79P .col-md-9{ width: 100%!important }
    .pM13z9{ height: auto!important }
    .pM13z9{ display: block!important }
    .pM13z9{ padding-top: 50%!important }
    .pM13z9{ width: 100%!important }
}
@media (min-width: 768px) and (max-width: 800px){
    body{ overflow-x: hidden }
    .Uc48Q7, .vl4W39{ display: block }
    .Aq134e, .Q2tC03, .k875GB{ display: none }
    .Lq451p .row .col-md-11{ width: 80% }
    .Lq451p .row .col-md-1{ width: 20% }
    .v4O89C{ height: auto!important }
    .v4O89C, .wN0r67, .wN0r67::before{ display: block!important }
    .v4O89C::before, .v4O89C::after, .uN459e, .wN0r67, .j94HK3::before{ height: auto!important }
    .v4O89C::before, .wN0r67::before, .j94HK3::before{ display: block!important }
    .v4O89C::before, .wN0r67::before, .j94HK3::before{ padding-top: 50% }
    .v4O89C::before, .v4O89C::after, .wN0r67::before, .j94HK3::before{ width: 100%!important }
    .wN0r67::before{ content: "" }
    .v4O89C::after{ position: relative!important }
    .uN459e, .Ef0d31, .Zf493o{ padding: 20px 20px!important }
    .B371q0 br, .W4g512{ display: none!important }
    .B371q0{ color: #192919!important }
    .v4O89C{ background: var(--react-green) }
    .B371q0{ color: #ffffff!important }
    .B371q0, .S8a89c, .J022yx{ width: 100%!important }
    .B371q0, .hG439F, .J022yx{ font-size: 25px!important }
    .B371q0, .hG439F{ line-height: 33px!important }
    .B371q0{ margin: 23px 0 0 0!important }
    .A49o37{ margin: 0 0 0 0!important }
    .A49o37{ height: auto!important }
    .S8a89c, .C3h5b8, .yZ9a75{ font-size: 18px!important }
    .S8a89c, .C3h5b8{ line-height: 28px!important }
    .S8a89c, .nW395v, .C3h5b8, .Ef0d31{ margin: 10px 0 0 0!important }
    .nW395v{ margin: 0!important }
    .d6J923{ margin: 0 0 14px 0!important }
    .A49o37{ background: white!important }
    .Un2v98, .F62fz9{ color: #192919!important }
    .dS2q53{ padding: 0 30px!important }
    .Un2v98{ font-size: 19px!important }
    .F62fz9{ font-size: 18px!important }
    .Un2v98{ line-height: 55px!important }
    .F62fz9{ line-height: 55px!important }
    .B371q0, .S8a89c, .hG439F, .C3h5b8, .J022yx, .yZ9a75{ text-align: center!important }
    .S8a89c::before{ content: "Erzurum'da Bahçe Sulama Sistemleri Hizmetleri Sunulmaktadır."!important }
    .d6J923{ width: 100%!important }
    .d6J923{ text-align: center!important }
    .d6J923{ line-height: 44px!important }
    .dS2q53{ height: 55px!important }
    .dS2q53{ border-bottom: 1px solid var(--react-simple) }
    .x46W3m{ display: none!important }
    .Wy021B, .fa66F7, .Ef0d31{ height: auto!important }
    .Wy021B{ margin: 0!important }
    .T4nG47{ padding: 20px 0 0 0!important }
    .bQ94U5{ display: none!important }
    .Ef0d31{ align-items: center!important }
    .Wy021B::before{ width: 100%!important }
    .Wy021B::before{ background: white!important }
    .A98nj3{ margin: 10px 0 0 0!important }
    .Vk449i, .Hhu256{ height: auto!important }
    .Hhu256{ margin: 0!important }
    .iY070W{ padding: 0 20px!important }
    .Ef0d31{ padding-top: 5px!important }
    .j5D7Y1{ margin: 0!important }
    .Ut557S{ margin: 0!important }
    .Po583d{ margin: 0!important }
    .Po583d{ box-shadow: none!important }
    .Lx375Z{ padding-top: 0!important }
    .e8A61l{ font-size: 19px!important }
    .F70ce3{ margin-bottom: 0!important }
    .j94HK3::before{ position: relative!important }
    .j94HK3::after{ width: 100%!important }
    .j94HK3::after{ height: auto!important }
    .Zf493o{ height: auto!important }
    .Xj06u6{ margin: 0!important }
    .J022yx{ line-height: 30px!important }
    .J022yx{ margin: 0!important }
    .yZ9a75{ line-height: 25px!important }
    .D0f783{ margin: 12px 0 0 0!important }
    .yZ9a75{ margin-bottom: 5px!important }
    .Tg352A{ width: 100%!important }
    .T1Gj62, .j94HK3{ height: auto!important }
    .Tg352A{ margin-bottom: 55px!important }
    .e8A61l, .j84N4b, .F70ce3{ text-align: center!important }
    .hG439F br, .C3h5b8 br{ display: none!important }
    .r9714B{ display: none!important }
    .pM13z9{ height: auto!important }
    .pM13z9{ float: none!important }
    .pM13z9{ display: block!important }
    .pM13z9{ padding-top: 50%!important }
    .pM13z9{ width: 100%!important }
    .pM13z9::before{ height: 100%!important }
    .Sm2277{ margin: -50px 0 0 0!important }
    .uP71E5, .Ur628J{ padding: 24px!important }
    .uP71E5{ font-size: 18px!important }
    .uP71E5{ line-height: 27px!important }
    .Vh322X .col-md-1, .Ur628J .col-md-1{ width: 50%!important }
    .gB956B{ overflow: hidden }
    .gB956B{ text-overflow: ellipsis }
    .gB956B{ white-space: nowrap }
    .gF5x66{ min-height: 100%!important }
    .gF5x66{ min-width: 100%!important }
    .xT8051{ margin: 0!important }
    .Dl412q li{ width: 100%!important }
    .Dl412q li:nth-child(2){ margin-top: 4px!important }
    .k6YS15{ margin: 8px 0 0 0!important }
    .Wy378q .col-md-1:nth-child(1) a{ margin: 0!important }
    .Wy378q .col-md-1:nth-child(2) a{ margin: 0!important }
    .ND5r57{ margin: 10px 0!important }
    .r9714B{ display: none!important }
    .S7j79P{ position: relative!important }
    .S7j79P .offset-md-3{ margin-left: 0!important }
    .S7j79P .col-md-9{ width: 100%!important }
    .pM13z9{ height: auto!important }
    .pM13z9{ display: block!important }
    .pM13z9{ padding-top: 50%!important }
    .pM13z9{ width: 100%!important }
}
@media (min-width:481px) and (max-width:767px){
    body{ overflow-x: hidden }
    .Uc48Q7, .vl4W39{ display: block }
    .Aq134e, .Q2tC03, .k875GB{ display: none }
    .Lq451p .row .col-md-11{ width: 80% }
    .Lq451p .row .col-md-1{ width: 20% }
    .v4O89C{ height: auto!important }
    .v4O89C, .wN0r67, .wN0r67::before{ display: block!important }
    .v4O89C::before, .v4O89C::after, .uN459e, .wN0r67, .j94HK3::before{ height: auto!important }
    .v4O89C::before, .wN0r67::before, .j94HK3::before{ display: block!important }
    .v4O89C::before, .wN0r67::before, .j94HK3::before{ padding-top: 50% }
    .v4O89C::before, .v4O89C::after, .wN0r67::before, .j94HK3::before{ width: 100%!important }
    .wN0r67::before{ content: "" }
    .v4O89C::after{ position: relative!important }
    .uN459e, .Ef0d31, .Zf493o{ padding: 20px 20px!important }
    .B371q0 br, .W4g512{ display: none!important }
    .B371q0{ color: #192919!important }
    .v4O89C{ background: var(--react-green) }
    .B371q0{ color: #ffffff!important }
    .B371q0, .S8a89c, .J022yx{ width: 100%!important }
    .B371q0, .hG439F, .J022yx{ font-size: 25px!important }
    .B371q0, .hG439F{ line-height: 33px!important }
    .B371q0{ margin: 23px 0 0 0!important }
    .A49o37{ margin: 0 0 0 0!important }
    .A49o37{ height: auto!important }
    .S8a89c, .C3h5b8, .yZ9a75{ font-size: 18px!important }
    .S8a89c, .C3h5b8{ line-height: 28px!important }
    .S8a89c, .nW395v, .C3h5b8, .Ef0d31{ margin: 10px 0 0 0!important }
    .nW395v{ margin: 0!important }
    .d6J923{ margin: 0 0 14px 0!important }
    .A49o37{ background: white!important }
    .Un2v98, .F62fz9{ color: #192919!important }
    .dS2q53{ padding: 0 30px!important }
    .Un2v98{ font-size: 19px!important }
    .F62fz9{ font-size: 18px!important }
    .Un2v98{ line-height: 55px!important }
    .F62fz9{ line-height: 55px!important }
    .B371q0, .S8a89c, .hG439F, .C3h5b8, .J022yx, .yZ9a75{ text-align: center!important }
    .S8a89c::before{ content: "Erzurum'da Bahçe Sulama Sistemleri Hizmetleri Sunulmaktadır."!important }
    .d6J923{ width: 100%!important }
    .d6J923{ text-align: center!important }
    .d6J923{ line-height: 44px!important }
    .dS2q53{ height: 55px!important }
    .dS2q53{ border-bottom: 1px solid var(--react-simple) }
    .x46W3m{ display: none!important }
    .Wy021B, .fa66F7, .Ef0d31{ height: auto!important }
    .Wy021B{ margin: 0!important }
    .T4nG47{ padding: 20px 0 0 0!important }
    .bQ94U5{ display: none!important }
    .Ef0d31{ align-items: center!important }
    .Wy021B::before{ width: 100%!important }
    .Wy021B::before{ background: white!important }
    .A98nj3{ margin: 10px 0 0 0!important }
    .Vk449i, .Hhu256{ height: auto!important }
    .Hhu256{ margin: 0!important }
    .iY070W{ padding: 0 20px!important }
    .Ef0d31{ padding-top: 5px!important }
    .j5D7Y1{ margin: 0!important }
    .Ut557S{ margin: 0!important }
    .Po583d{ margin: 0!important }
    .Po583d{ box-shadow: none!important }
    .Lx375Z{ padding-top: 0!important }
    .e8A61l{ font-size: 19px!important }
    .F70ce3{ margin-bottom: 0!important }
    .j94HK3::before{ position: relative!important }
    .j94HK3::after{ width: 100%!important }
    .j94HK3::after{ height: auto!important }
    .Zf493o{ height: auto!important }
    .Xj06u6{ margin: 0!important }
    .J022yx{ line-height: 30px!important }
    .J022yx{ margin: 0!important }
    .yZ9a75{ line-height: 25px!important }
    .D0f783{ margin: 12px 0 0 0!important }
    .yZ9a75{ margin-bottom: 5px!important }
    .Tg352A{ width: 100%!important }
    .T1Gj62, .j94HK3{ height: auto!important }
    .Tg352A{ margin-bottom: 55px!important }
    .e8A61l, .j84N4b, .F70ce3{ text-align: center!important }
    .hG439F br, .C3h5b8 br{ display: none!important }
    .r9714B{ display: none!important }
    .pM13z9{ height: auto!important }
    .pM13z9{ float: none!important }
    .pM13z9{ display: block!important }
    .pM13z9{ padding-top: 50%!important }
    .pM13z9{ width: 100%!important }
    .pM13z9::before{ height: 100%!important }
    .Sm2277{ margin: -50px 0 0 0!important }
    .uP71E5, .Ur628J{ padding: 24px!important }
    .uP71E5{ font-size: 18px!important }
    .uP71E5{ line-height: 27px!important }
    .Vh322X .col-md-1, .Ur628J .col-md-1{ width: 50%!important }
    .gB956B{ overflow: hidden }
    .gB956B{ text-overflow: ellipsis }
    .gB956B{ white-space: nowrap }
    .gF5x66{ min-height: 100%!important }
    .gF5x66{ min-width: 100%!important }
    .xT8051{ margin: 0!important }
    .Dl412q li{ width: 100%!important }
    .Dl412q li:nth-child(2){ margin-top: 4px!important }
    .k6YS15{ margin: 8px 0 0 0!important }
    .Wy378q .col-md-1:nth-child(1) a{ margin: 0!important }
    .Wy378q .col-md-1:nth-child(2) a{ margin: 0!important }
    .ND5r57{ margin: 10px 0!important }
}
@media (min-width:320px) and (max-width:480px){
    body{ overflow-x: hidden }
    .Uc48Q7, .vl4W39{ display: block }
    .Aq134e, .Q2tC03, .k875GB{ display: none }
    .Lq451p .row .col-md-11{ width: 80% }
    .Lq451p .row .col-md-1{ width: 20% }
    .v4O89C{ height: auto!important }
    .v4O89C, .wN0r67, .wN0r67::before{ display: block!important }
    .v4O89C::before, .v4O89C::after, .uN459e, .wN0r67, .j94HK3::before{ height: auto!important }
    .v4O89C::before, .wN0r67::before, .j94HK3::before{ display: block!important }
    .v4O89C::before, .wN0r67::before, .j94HK3::before{ padding-top: 50% }
    .v4O89C::before, .v4O89C::after, .wN0r67::before, .j94HK3::before{ width: 100%!important }
    .wN0r67::before{ content: "" }
    .v4O89C::after{ position: relative!important }
    .uN459e, .Ef0d31, .Zf493o{ padding: 20px 20px!important }
    .B371q0 br, .W4g512{ display: none!important }
    .B371q0{ color: #192919!important }
    .v4O89C{ background: var(--react-green) }
    .B371q0{ color: #ffffff!important }
    .B371q0, .S8a89c, .J022yx{ width: 100%!important }
    .B371q0, .hG439F, .J022yx{ font-size: 25px!important }
    .B371q0, .hG439F{ line-height: 33px!important }
    .B371q0{ margin: 23px 0 0 0!important }
    .A49o37{ margin: 0 0 0 0!important }
    .A49o37{ height: auto!important }
    .S8a89c, .C3h5b8, .yZ9a75{ font-size: 18px!important }
    .S8a89c, .C3h5b8{ line-height: 28px!important }
    .S8a89c, .nW395v, .C3h5b8, .Ef0d31{ margin: 10px 0 0 0!important }
    .nW395v{ margin: 0!important }
    .d6J923{ margin: 0 0 14px 0!important }
    .A49o37{ background: white!important }
    .Un2v98, .F62fz9{ color: #192919!important }
    .dS2q53{ padding: 0 30px!important }
    .Un2v98{ font-size: 19px!important }
    .F62fz9{ font-size: 18px!important }
    .Un2v98{ line-height: 55px!important }
    .F62fz9{ line-height: 55px!important }
    .B371q0, .S8a89c, .hG439F, .C3h5b8, .J022yx, .yZ9a75{ text-align: center!important }
    .S8a89c::before{ content: "Erzurum'da Bahçe Sulama Sistemleri Hizmetleri Sunulmaktadır."!important }
    .d6J923{ width: 100%!important }
    .d6J923{ text-align: center!important }
    .d6J923{ line-height: 44px!important }
    .dS2q53{ height: 55px!important }
    .dS2q53{ border-bottom: 1px solid var(--react-simple) }
    .x46W3m{ display: none!important }
    .Wy021B, .fa66F7, .Ef0d31{ height: auto!important }
    .Wy021B{ margin: 0!important }
    .T4nG47{ padding: 20px 0 0 0!important }
    .bQ94U5{ display: none!important }
    .Ef0d31{ align-items: center!important }
    .Wy021B::before{ width: 100%!important }
    .Wy021B::before{ background: white!important }
    .A98nj3{ margin: 10px 0 0 0!important }
    .Vk449i, .Hhu256{ height: auto!important }
    .Hhu256{ margin: 0!important }
    .iY070W{ padding: 0 20px!important }
    .Ef0d31{ padding-top: 5px!important }
    .j5D7Y1{ margin: 0!important }
    .Ut557S{ margin: 0!important }
    .Po583d{ margin: 0!important }
    .Po583d{ box-shadow: none!important }
    .Lx375Z{ padding-top: 0!important }
    .e8A61l{ font-size: 19px!important }
    .F70ce3{ margin-bottom: 0!important }
    .j94HK3::before{ position: relative!important }
    .j94HK3::after{ width: 100%!important }
    .j94HK3::after{ height: auto!important }
    .Zf493o{ height: auto!important }
    .Xj06u6{ margin: 0!important }
    .J022yx{ line-height: 30px!important }
    .J022yx{ margin: 0!important }
    .yZ9a75{ line-height: 25px!important }
    .D0f783{ margin: 12px 0 0 0!important }
    .yZ9a75{ margin-bottom: 5px!important }
    .Tg352A{ width: 100%!important }
    .T1Gj62, .j94HK3{ height: auto!important }
    .Tg352A{ margin-bottom: 55px!important }
    .e8A61l, .j84N4b, .F70ce3{ text-align: center!important }
    .hG439F br, .C3h5b8 br{ display: none!important }
    .r9714B{ display: none!important }
    .pM13z9{ height: auto!important }
    .pM13z9{ float: none!important }
    .pM13z9{ display: block!important }
    .pM13z9{ padding-top: 50%!important }
    .pM13z9{ width: 100%!important }
    .pM13z9::before{ height: 100%!important }
    .Sm2277{ margin: -50px 0 0 0!important }
    .uP71E5, .Ur628J{ padding: 24px!important }
    .uP71E5{ font-size: 18px!important }
    .uP71E5{ line-height: 27px!important }
    .Vh322X .col-md-1, .Ur628J .col-md-1{ width: 50%!important }
    .gB956B{ overflow: hidden }
    .gB956B{ text-overflow: ellipsis }
    .gB956B{ white-space: nowrap }
    .gF5x66{ min-height: 100%!important }
    .gF5x66{ min-width: 100%!important }
    .xT8051{ margin: 0!important }
    .Dl412q li{ width: 100%!important }
    .Dl412q li:nth-child(2){ margin-top: 4px!important }
    .k6YS15{ margin: 8px 0 0 0!important }
    .Wy378q .col-md-1:nth-child(1) a{ margin: 0!important }
    .Wy378q .col-md-1:nth-child(2) a{ margin: 0!important }
    .ND5r57{ margin: 10px 0!important }

    .Lr88Q6 .col-md-4{ width: 100%!important }
}